module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1618264574931970"},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://5c2f839284eb48213616422e05939c26@o207017.ingest.us.sentry.io/4508002691252224","sampleRate":0.4},
    },{
      plugin: require('../../gatsby-theme-agria/gatsby-browser.tsx'),
      options: {"plugins":[],"enableTrustpilot":false,"sentryDSN":"https://5c2f839284eb48213616422e05939c26@o207017.ingest.us.sentry.io/4508002691252224"},
    },{
      plugin: require('../../gatsby-theme-vet-online-claims/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/agria.png","name":"Kennel Club Insurance Customer Online Claim","short_name":"Kennel Club Insurance Customer Online Claim","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"506307a686b6e3fedd92c238f12c7ba7"},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
